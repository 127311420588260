// hooks.client.js
import { init } from "@jill64/sentry-sveltekit-cloudflare/client"
// or
// import { clientInit } from '@jill64/sentry-sveltekit-cloudflare'

const onError = init(
  "https://98ec20abb4484a87a0216d7781b6a652@o4508357129732096.ingest.us.sentry.io/4508357140873216",
  {
    sentryOptions: {
      // integrations: [
      // Sentry.browserTracingIntegration(),
      // Sentry.browserProfilingIntegration(),
      // Sentry.replayIntegration()
      // ],
      tracesSampleRate: 1.0,
    },
    enableInDevMode: true,
  },
)

export const handleError = onError((e, sentryEventId) => {
  console.log("handleError", e, sentryEventId)
})
