import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38')
];

export const server_loads = [7,2];

export const dictionary = {
		"/(marketing)/(with-header)": [22,[7,8]],
		"/(marketing)/(with-header)/about": [23,[7,8]],
		"/(marketing)/(with-header)/blog": [24,[7,8]],
		"/(marketing)/(with-header)/blog/[slug]": [25,[7,8]],
		"/(admin)/brand/[teamSlug]/(menu)": [~10,[2,3]],
		"/(admin)/brand/[teamSlug]/api": [17,[2]],
		"/(admin)/brand/[teamSlug]/(menu)/competitions/[id]": [~11,[2,3]],
		"/(admin)/brand/[teamSlug]/competitions/[id]/edit": [~18,[2]],
		"/(admin)/brand/[teamSlug]/(menu)/settings": [12,[2,3,4]],
		"/(admin)/brand/[teamSlug]/(menu)/settings/billing": [~13,[2,3,4]],
		"/(admin)/brand/[teamSlug]/(menu)/settings/integrations": [14,[2,3,4]],
		"/(admin)/brand/[teamSlug]/(menu)/settings/members": [~15,[2,3,4]],
		"/(admin)/brand/[teamSlug]/(menu)/users": [~16,[2,3]],
		"/(marketing)/(with-header)/customers": [26,[7,8]],
		"/(giveaway)/c/[slug]": [~20,[6]],
		"/(giveaway)/c/[slug]/embed": [~21,[6]],
		"/(marketing)/features": [32,[7]],
		"/(marketing)/forgot-password": [33,[7]],
		"/(marketing)/(with-header)/integrations": [27,[7,8]],
		"/(marketing)/invite/[id]": [~34,[7]],
		"/(admin)/onboarding": [19,[2,5]],
		"/(marketing)/(with-header)/pricing": [28,[7,8]],
		"/(marketing)/(with-header)/privacy": [29,[7,8]],
		"/(marketing)/sign-in": [35,[7]],
		"/sign-out": [~38],
		"/(marketing)/sign-up": [36,[7]],
		"/(marketing)/(with-header)/support": [30,[7,8]],
		"/(marketing)/(with-header)/terms": [31,[7,8]],
		"/(marketing)/update-password": [37,[7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';